<template>
  <div class="clue">
    <div class="container">
      <!-- 顶部卡片 -->
      <el-card class="top_card">
        <el-form ref="form" :model="queryInfo" label-width="120">
          <el-form-item label="商机状态：">
            <el-radio-group v-model="queryInfo.clueStage" @change="changeSearchangeSearch">
              <el-radio-button :label="''" :key="''"> 全部 </el-radio-button>
              <el-radio-button :label="item.codeName" v-for="item in stageList" :key="item.id">
                {{ item.codeName }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="项目类型：">
            <el-radio-group v-model="queryInfo.clueType" @change="changeSearchangeSearch">
              <el-radio-button :label="''" :key="''"> 全部 </el-radio-button>
              <el-radio-button :label="item.codeName" v-for="item in typeList" :key="item.id">
                {{ item.codeName }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <div class="newClue">
            <el-form-item label="所在城市：" style="width: 60%">
              <city-select
                ref="child"
                :province="queryInfo.province"
                :city="queryInfo.city"
                :district="queryInfo.district"
                @searchProvince="searchProvince"
                @searchCity="searchCity"
                @searchDistrict="searchDistrict"
              />
            </el-form-item>
            <div class="search_right">
              <el-input
                placeholder="请输入线索标题、企业名称"
                v-model="queryInfo.keys"
                class="input-with-select"
                style="width: 500px"
                clearable
                @change="changeSearchangeSearch"
              >
                <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
              </el-input>
            </div>
          </div>
        </el-form>
      </el-card>
      <div style="display: flex; align-items: center; padding: 10px 0px">
        <div style="padding-right: 50px">
          <span>当前条件下共查出{{ total }}条线索</span>
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按有效截止时间
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp1"
              :style="{ 'border-bottom-color': orderinfo.color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown2"
              :style="{ 'border-top-color': orderinfo.color2 }"
            ></span>
          </div>
        </div>
      </div>
      <!-- 卡片列表 -->
      <div class="card_list" v-loading="loading">
        <div class="clue_card" v-for="(item, index) in datalist" :key="index">
          <div class="card_left">
            <!-- 企业图片区域 -->
            <div class="clue_img">
              <div v-if="item.clueStage == '项目冻结'" class="img_left_top three">
                <span>{{ item.clueStage }}</span>
              </div>
              <div v-if="item.clueStage == '项目终止'" class="img_left_top five">
                <span>{{ item.clueStage }}</span>
              </div>
              <div class="img_left_top two" v-if="item.clueStage && item.clueStage != '项目终止' && item.clueStage != '项目冻结'">
                <span>{{ item.clueStage }}</span>
              </div>
              <img :src="item.clueImage" alt="图片" />
            </div>
            <!-- 企业信息 -->
            <div class="card_info">
              <div class="card_title row">
                <div>
                  <span class="clue_name">{{ item.clueName }}</span>
                </div>
                <div class="type_culb" v-if="!item.clueCost">
                  <img src="../../../assets/image/Vector.png" alt="" />
                  <span style="margin-left: 5px">免费</span>
                </div>
                <div class="type_culb" v-else>
                  <span style="margin-right: 10px">押金￥{{ item.deposit }}</span>
                  <span>线索费￥{{ item.clueCost }}</span>
                </div>
              </div>
              <div style="height: 25px">
                <el-row class="row" v-if="item.clueTable">
                  <el-tag
                    effect="plain"
                    v-for="(i1, i2) in newClueTableList(item.clueTable).slice(0, 2)"
                    :key="i2"
                    >{{ i1 }}</el-tag
                  >
                  <div @mouseenter="showTags(index)" @mouseleave="hideTags" class="show_tags">
                    <el-tag style="cursor: pointer; background: #fff"
                      >{{ newClueTableList(item.clueTable).length }}+</el-tag
                    >
                    <!-- 要展示的更多标签 -->
                    <div class="tags_box" v-show="isShowTags && tagIndex == index">
                      <el-tag
                        effect="plain"
                        v-for="(i1, i2) in newClueTableList(item.clueTable)"
                        :key="i2"
                        >{{ i1 }}</el-tag
                      >
                    </div>
                  </div>
                </el-row>
              </div>
              <div style="display: flex" class="row">
                <div class="corp_name">{{ item.companyFullName }}</div>
              </div>
              <div class="bottom_row">
                <span style="margin-right: 20px">
                  所在地区：{{ item.province }}-{{ item.city }}
                </span>
                <span>有效期：{{ item.clueStartTime }}至{{ item.estimatedCompletionTime }}</span>
              </div>
            </div>
          </div>
          <!-- 数据统计 -->
          <div class="count_list">
            <div class="col first_col" @click="serviceBtn(item)">
              <div class="row">{{ item.serviceNum || 0 }}</div>
              <div>
                <span>服务商</span>
              </div>
              <div style="margin-top: 5px">
                <el-tag size="mini" style="font-size: 12px">{{ item.num }}个服务商正在跟进中</el-tag>
              </div>
            </div>
            <div class="col" @click="peopleClick(item)">
              <div class="row">{{ item.specialistNum || 0 }}</div>
              <div>
                <span>专家</span>
              </div>
            </div>
            <div class="col" @click="clueLogClick(item)">
              <div class="row">{{ item.clueLogNum || 0 }}</div>
              <div>
                <span>商机日志</span>
              </div>
            </div>
          </div>

          <!-- 右侧按钮 -->
          <div class="card_right">
            <div class="btn_list row">
              <span class="click" style="color: #4e93fb" @click="goMatchingManagement(item)">匹配管理</span>
              <el-popover placement="bottom" width="80" trigger="click">
                <div>
                  <div
                    class="popover_text"
                    style="margin-bottom: 10px"
                    @click="exportDocument(item)"
                  >
                    <div>需求文档</div>
                    <i class="el-icon-download"></i>
                  </div>
                  <div class="popover_text" @click="exportSelectionDocument(item)">
                    <div>甄选分析报告</div>
                    <i class="el-icon-download"></i>
                  </div>
                </div>

                <span slot="reference" class="click" style="color: #4e93fb">导出文档</span>
              </el-popover>
              <span class="click" style="color: #4e93fb" @click="clueAssessment(item)"
                >商机评估</span
              >
              <span @click="editClueBtn(item)" class="click" style="color: #4e93fb">编辑</span>
            </div>
            <div style="position: relative; top: 30px" class="row">
              <span class="operate">操作人：{{ item.userName }}</span>
              <div>
                <span class="operate">最近操作时间：{{ item.updateTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import CitySelect from '@/components/CitySelect.vue'
import { serverApiListPage } from '@/api/opportunity.js'
import { downloadPdf,downloadAnalysisPdf } from '@/api/clue.js'
import { getCode } from '@/api/demand.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  orders: 0, // 排序 默认传1 2->按创建时间升序;3->按创建时间降序;4->按押金升序;5->按押金降序;6->按线索费升序;7->按线索费降序8->按查看升序;9->按查看降序;10->按分享升序;11->按分享降序;12->按推送升序;13->按推送降序
  city: '', //城市
  province: '', //省份
  district: '', // 区县
  clueResource: '', // 线索来源
  clueStage: '', // 线索阶段
  clueType: '', //线索类型
  keys: '' //线索标题/企业名称
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6'
})

export default {
  components: { CitySelect },
  name: 'clue',
  data() {
    return {
      //查询参数
      queryInfo: { ...defaultQueryInfo },
      total: 0,
      //排序字段
      orderinfo: { ...defaultOrder },
      //线索卡片列表
      datalist: [],
      //线索类型
      typeList: [],
      //线索阶段
      stageList: [],
      menuRules: {
        clueStage: [{ required: true, message: '请选择线索阶段', trigger: 'change' }]
      },
      //控制是否显示更多标签
      isShowTags: false,
      dialogVisible: false, //修改线索阶段
      tagIndex: '',
      codeType: [],
      param: {
        clueSmartId: '', //线索编号
        clueStage: '' //线索阶段
      },
      loading: false
    }
  },
  created() {
    // 获取线索类型
    this.getCodeType('019')
    // 获取线索阶段
    this.getCodeState('034')
    if (this.$route.query.pageNum) {
      this.queryInfo.pageNum = Number(this.$route.query.pageNum)
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  computed: {},
  methods: {
    // 商机评估
    clueAssessment(row) {
      this.$router.push({
        name: 'assessmentOpportunity',
        query: {
          id: row.id
        }
      })
    },
    // 获取数据
    async getCodeType(val) {
      const { data: res } = await getCode({ codeType: val })
      if (res.resultCode == 200) {
        this.typeList = res.data
      }
    },
    async getCodeState(val) {
      const { data: res } = await getCode({ codeType: val })
      if (res.resultCode == 200) {
        if (val == '034') {
          this.stageList = res.data
        }
      }
    },
    newClueTableList(val) {
      return val.split(',')
    },
    //请求数据
    async search() {
      this.loading = true
      const { data: res } = await serverApiListPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.datalist = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取线索信息失败！')
      }
      this.loading = false
    },
    //编辑线索按钮
    editClueBtn(row) {
      this.$router.push({
        name: 'compile',
        query: {
          clueId: row.id,
          headTabActive: 0,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    // 导出线索需求文档
    async exportClueDemand(data) {
      const res = await downloadPdf({ clueId: data })
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `线索需求文档${nowtime}.docx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('下载失败!')
      }
    },
    exportDocument(item) {
      this.exportClueDemand(item.id)
    },

    // 导出线索甄选文档
    async exportSelectionDemand(data) {
      const res = await downloadAnalysisPdf({ clueId: data })
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `甄选分析文档${nowtime}.docx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('下载失败!')
      }
    },
    exportSelectionDocument(item) {
      this.exportSelectionDemand(item.id)
    },

    //取消弹窗
    handleCloseMenu() {
      this.dialogVisible = false
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    //排序按创建时间
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '1'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    showTags(i) {
      this.tagIndex = i
      this.isShowTags = true
    },
    hideTags() {
      this.isShowTags = false
    },
    //跳转到线索日志
    clueLogClick(row) {
      this.$router.push({
        name: 'journalOpportunity',
        query: {
          id: row.id
        }
      })
    },
    //跳转到专家
    peopleClick(row) {
      this.$router.push({
        name: 'expertOpportunity',
        query: {
          id: row.id
        }
      })
    },
    // 跳转服务商
    serviceBtn(row) {
      this.$router.push({
        name: 'serviceOpportunity',
        query: {
          id: row.id
        }
      })
    },
    // 跳转商机管理
    goMatchingManagement(row){
      this.$router.push({
        name: 'matchingManagement',
        query: {
          clueId: row.id
        }
      })
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.clue {
  overflow: hidden;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
  }
  ::v-deep .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
.newClue {
  display: flex;
  justify-content: space-between;
}
.search_right {
  width: 500px;
  margin-right: 40px;
  ::v-deep .el-input-group__append {
    border-right: 0;
  }
  ::v-deep .el-button {
    border: #448aff 1px solid;
    color: #fff;
    background-color: #448aff;
  }
}
.main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}

.top_card {
  margin-bottom: 10px;
}
.card_list {
  width: 100%;
  min-height: 58vh;
}
.clue_card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
}

.card_left {
  display: flex;
  width: 40%;
  height: 100%;
  .clue_img {
    flex-shrink: 0;
    position: relative;
    width: 160px;
    height: 104px;
    margin-right: 10px;
    border-radius: 1px;
    img {
      width: 160px;
      height: 104px;
      border-radius: 8px;
    }
  }
  .img_left_top {
    position: absolute;
    width: 106px;
    height: 34px;
    color: #fff;
    text-align: center;
    line-height: 34px;
    border-radius: 10px 10px 10px 0;
    top: 0;
    left: 0;
    transform: translate(-10px, -10px);
  }
  .one {
    background: #ff7d18;
  }
  .two {
    background: #599afb;
  }
  .three {
    background: #999999;
  }
  .four {
    background: #2cca4f;
  }
  .five {
    background: #ff7158;
  }
  .card_info {
    flex: 1;
    .el-row {
      display: flex;
      flex-flow: row nowrap;
    }
    .show_tags {
      position: relative;
      width: 50px;
      .tags_box {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        width: 400px;
        padding-left: 10px;
        background-color: rgba(241, 241, 241, 0.6);
        box-shadow: 0 2px 4px rgba(241, 241, 241, 0.25);
        position: absolute;
        left: 100%;
        top: 100%;
        z-index: 999 !important;
        .el-tag {
          height: 18px;
          margin: 10px 10px 0 0;
          padding: 0 5px;
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
    .row {
      margin-bottom: 10px;
    }
    .clue_name {
      font-size: 18px;
      font-weight: bolder;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      min-width: 40px;
      max-width: 200px;
      -webkit-box-orient: vertical;
    }
    .el-tag {
      height: 18px;
      margin-right: 10px;
      padding: 0 5px;
      line-height: 16px;
      font-size: 12px;
    }
    .card_title {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      .type_culb {
        box-sizing: border-box;
        padding: 2px 6px;
        border-radius: 4px;
        color: rgb(146, 141, 141);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .corp_name {
      font-size: 14px;
      line-height: 20px;
    }
    .clue_stage {
      color: #4e93fb;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .bottom_row {
      display: flex;
      font-size: 14px;
      line-height: 20px;
    }
    .middle_row {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .clue_num {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6e6e6e;
      font-size: 14px;
      margin-right: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .souce {
    width: 166px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .souce_item {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-left: 20px;

      span {
        font-size: 24px;
        color: #4e93fb;
        font-weight: bold;
        line-height: 26px;
      }
    }
  }
}

.count_list {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 30%;
  .row {
    color: #078bff;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .col {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
  }
  .first_col {
    border-right: 1px solid #c5c7c55d;
    padding-right: 90px;
  }
  :hover span {
    color: #4e93fb;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.card_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 20%;
  .frozen {
    color: #fd5469;
    cursor: pointer;
  }
  .row {
    margin-bottom: 10px;
  }
  .btn_list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    position: relative;
    margin-left: 50px;
    top: 20px;
    margin-bottom: 30px;
    .click {
      cursor: pointer;
    }
  }
  .operate {
    display: flex;
    justify-content: right;
    font-size: 10px;
    color: #999;
    margin-bottom: 10px;
  }
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.popover_text {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .el-icon-download {
    font-size: 20px;
  }
}
::v-deep .count_list span{
  line-height: 19px;
}
</style>
